
import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue';
import { BusyService } from '@/services/BusyService';
import { ApiService } from '@/services/ApiService';
import { Stanza } from '@/models/Stanza';
import { TerritoryService } from '@/services/TerritoryService';

@Component({ 
  components:{ 
    Modal
  }
})
export default class LyricsModal extends Vue {
  data: Stanza[] | null = null
  songNumber ='';
  songTitle ='';

  async init(songNumber: string, songTitle: string){ 
    this.songNumber = songNumber;
    this.songTitle = songTitle;
    BusyService.showBusy();
    this.data = await ApiService.post('/api/GetLyrics', {songNumber: parseInt(songNumber), territoryId: parseInt(TerritoryService.territoryId) })
    BusyService.hideBusy();
    (this.$refs.modal as Modal).show();
  }

  formatLines(lines: string){ 
    return lines.replaceAll('|', '<br>');
  }
}
